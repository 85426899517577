<template>
    <section class="inner-banner">
        <h2 v-if="!verified">{{ $t('Verifying email') }}...</h2>
        <div v-else>
            <h2>{{ $t('Congratulations') }}!</h2>
            <p>{{ $t('Your Email has been verified') }}.</p>
        </div>
    </section>
</template>

<script>
import {userService} from '@/services/userService'

export default {
    name: "VerifyEmail",
    data() {
        return {
            uriParams: {
                id: this.$route.params.id,
                hash: this.$route.params.hash
            },
            verified: false,
        }
    },
    created() {
        userService.verify(this.uriParams).then(() => this.verified = true)
    }
}
</script>

<style scoped>

</style>